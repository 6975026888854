@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "satoshi";
  src: url(./assets//Satoshi_Complete/Fonts/Variable/Satoshi-Variable.ttf);
  font-weight: 600;
}

/* @font-face {
  font-family: "satoshiv";
  src: url(./assets//Satoshivariable.ttf);
  font-weight: 600;
} */

.font-satoshi {
  font-family: "satoshi";
}

td,
tr {
  white-space: nowrap;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  /* opacity: 0.1; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/**
Loader
*/
.loader {
  display: block;
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  color: #467fcf;
}

.loader:before,
.loader:after {
  width: 2.5rem;
  height: 2.5rem;
  margin: -1.25rem 0 0 -1.25rem;
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
}

.loader:before {
  border-radius: 50%;
  border: 3px solid currentColor;
  opacity: .15;
}

.loader:after {
  -webkit-animation: loader .6s linear;
  animation: loader .6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 50%;
  border: 3px solid;
  border-color: transparent;
  border-top-color: currentColor;
  box-shadow: 0 0 0 1px transparent;
}


@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.vp-loader {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  color: #ffffff;
}


.vp-loader::before,
.vp-loader::after {
  width: 30px;
  height: 30px;
  display: inline-flex;
  position: absolute;
  content: '';
  border-radius: 100px;
  top: 0;
  left: 0;
}

.vp-loader::before {
  border: 2px solid currentColor;
  opacity: .15;
}

.vp-loader::after {
  border: 2px solid;
  border-color: transparent;
  border-top-color: currentColor;

  -webkit-animation: spin-loader .6s linear;
  animation: spin-loader .6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}


.vp-loader.sm,
.vp-loader.sm::before,
.vp-loader.sm::after {
  width: 20px;
  height: 20px;
}

.vp-loader.sm::before {
  border: 0.1rem solid currentColor;
  opacity: .15;
}

.vp-loader.sm::after {
  border: 0.1rem solid;
  border-color: transparent;
  border-top-color: currentColor;
}

.toast-inner {
  position: fixed;
  z-index: 5000;
  width: 300px;
  padding: 0.55rem 0.95rem;
  border-radius: 8px;
  min-height: 68px;
  background-color: #2196F3;
  box-shadow: 0 8px 40px 0 rgba(96, 108, 115, 0.2);

  top: 0;
  margin-right: 2rem;
  margin-left: 2rem;
  line-height: 20px;
}

.toast-inner.top-left {
  top: 0;
  margin-top: 3rem;
  left: 0;
}

.toast-inner.top-right {
  top: 0;
  margin-top: 3rem;
  right: 0;
}

.toast-inner.top-center {
  top: 0;
  margin-top: 3rem;
  margin-left: 28%;
}

.toast-inner.bottom-left {
  bottom: 0;
  margin-top: -3rem;
  left: 0;
}

.toast-inner.bottom-right {
  bottom: 0;
  margin-top: -3rem;
  right: 0;
}

.toast-inner.bottom-center {
  bottom: 0;
  margin-top: -3rem;
  margin-left: 28%;
}

.toast-inner.toaster {
  display: flex;
  align-items: center;
  min-height: 50px;
  border-radius: 8px;
}


@-webkit-keyframes spin-loader {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin-loader {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.teQAzf {
  max-width: 100vw !important;
}

.img {
  float: left;
  shape-outside: circle();
  shape-margin: 2rem;
}

@media (max-width: 576px) {
  .img {
    float: none;
  }
}